// import Web3 from 'web3';
import '../styles/index.scss';
// import chains from './chains.js';
import Web3 from './web3.js';
import rpc from './rpc.js';

const state = {
	value: {
		txHash: '',
		selectedFromKey: 0,
		selectedToKey: 0,
		paramsInput: false
	},
	replyChange() {
		if (this.value.txHash.startsWith('0x') && this.value.selectedFromKey !== 0 && this.value.selectedToKey !== 0) {
			if (this.value.selectedFromKey !== this.value.selectedToKey) {
				document.querySelector('#linkToBridge').childNodes[0].disabled = false;
			}
		} else {
			document.querySelector('#claimButton').innerText = 'Input something useful...';
			document.querySelector('#linkToBridge').childNodes[0].disabled = true;
		}
		if (state.getter.txHash === '' || '') {
			document.querySelector('#warning').innerText = '';
		} else if (state.getter.txHash.length != 66) {
			document.querySelector('#warning').innerText =
				"This is not a valid transaction hash, make sure it's a valid CCB deposit transaction hash.";
		}
	},
	openWindow() {
		(document.location.href = `https://garden.defido.com/bridge/tokens?release=${state.getter
			.selectedFromKey},${state.getter.selectedToKey},${state.getter.txHash}`),
			true;
	},
	get getter() {
		return this.value;
	},
	set setter(input) {
		Object.assign(this.value, input);
		this.replyChange();
	}
};

const getTransaction = async (transactionHash) => {
	// let counter = 0;
	// let rpcCounter = 0;
	var errorFromKey;
	var ccbContractAddress;
	document.querySelector('#warning').innerText = ``;
	document.querySelector('#linkToBridge').childNodes[0].disabled = true;
	document.querySelector('#claimButton').innerText = 'Loading...';
	document.querySelector('#walletAddress').innerText = '';
	let button = document.querySelector('#claimButton');
	button.classList.remove('btn-danger');
	button.classList.add('btn-success');
	// Poll all chains for a successful result
	try {
		for (let [ fromKey, rpcUrl ] of Object.entries(rpc[1])) {
			let web3Polling = new Web3(rpcUrl);
			const transactionPolling = await web3Polling.eth.getTransaction(transactionHash);
			if (transactionPolling != null) {
				var transaction = await web3Polling.eth.getTransaction(transactionHash);
				var web3 = new Web3(rpcUrl);
				var currentFromChainId = fromKey;
				console.log(transactionHash, fromKey, rpcUrl);
				errorFromKey = rpc[2][fromKey];
				console.log(errorFromKey);
			}
		}
		// Change the contract address for Avalanche, as it is different to the others
		if (currentFromChainId != '43114') {
			ccbContractAddress = '0xCBCe172d7af2616804ab5b2494102dAeC47B2635';
		} else {
			ccbContractAddress = '0x46325c7005F04900F8D74cD0eAB903597b6EFFFF';
		}
		// Remove the function ID by removing the first 8 characters after the 0x
		let removeFunctionIdReturnHexString = `0x${transaction.input.substr(10)}`;

		// Get the transaction reciept to pull logs
		const receipt = await web3.eth.getTransactionReceipt(transactionHash);

		// Filter logs for the contract address
		var contractLogsOfCCB = receipt['logs'].filter((x) => x.address == ccbContractAddress);

		// Filter those logs down to only the 'data'
		const dataOfCCB = contractLogsOfCCB[0]['data'];

		// Decode the data logs to get the depositNumber hex decoded
		const decodedLogs = await web3.eth.abi.decodeParameters(
			[
				{
					type: 'uint256',
					name: 'amount'
				},
				{
					type: 'uint256',
					name: 'depositChainId'
				},
				{
					type: 'uint256',
					name: 'releaseChainId'
				},
				{
					type: 'uint256',
					name: 'customersDepositNumber'
				}
			],
			dataOfCCB
		);
		// console.log('here', decodedLogs);
		// Decode the parameters to get the input data
		const decodedParams = await web3.eth.abi.decodeParameters(
			[
				{
					type: 'address',
					name: 'token'
				},
				{
					type: 'uint256',
					name: 'amount'
				},
				{
					type: 'address',
					name: 'receiverAddress'
				},
				{
					type: 'uint256',
					name: 'releaseChainId'
				}
			],
			removeFunctionIdReturnHexString
		);

		// console.log(decodedParams, Object.values(rpc[0]));
		if (decodedLogs['releaseChainId'] === decodedParams['releaseChainId']) {
			// console.log('pass');
			// console.log(transaction.to);
			console.log(decodedLogs['depositChainId'], decodedParams);
			if (transaction.to === ccbContractAddress) {
				// console.log('pass', 2);
				if (state.getter.paramsForward === true) {
					state.setter = { selectedFromKey: decodedLogs['depositChainId'] };
					state.setter = { selectedToKey: decodedParams['releaseChainId'] };
					state.openWindow();
				} else {
					let chainNameEnglish = rpc[3][decodedParams[3]];
					// console.log(chainNameEnglish);
					let etherScanUrlTo = rpc[2][decodedParams['releaseChainId']];
					let etherScanUrlFrom = rpc[2][decodedLogs['depositChainId']];
					let decodedDepositNumber = web3.utils.numberToHex(decodedLogs[3]).replace('0x', '');
					console.log(etherScanUrlTo, etherScanUrlFrom, decodedDepositNumber, chainNameEnglish);
					state.setter = { selectedFromKey: decodedLogs['depositChainId'] };
					state.setter = { selectedToKey: decodedParams['releaseChainId'] };
					document.querySelector('#walletAddress').innerText = decodedParams['receiverAddress'];
					let button = document.querySelector('#claimButton');
					button.innerText = 'Here is your bridge link';
					button.classList.remove('btn-danger');
					button.classList.add('btn-success');
					document.querySelector('#linkToBridge').childNodes[0].disabled = false;
					document.querySelector('#warning').innerText = ``;
				}
			}
		}
	} catch (error) {
		// counter++;
		document.querySelector('#warning').innerText = `There's an error make sure this is your DEPOSIT tx hash`;
		let button = document.querySelector('#claimButton');
		button.innerText = 'Failed';
		button.classList.remove('btn-success');
		button.classList.add('btn-danger');
		document.querySelector('#linkToBridge').childNodes[0].disabled = true;
		errorFromKey.substring(0, 8);
		let returnError = errorFromKey.includes('http');
		if (!returnError) {
			// message.reply(
			// 	`Beep Boop, I didn't quite understand that txHash: @MODS here is the transaction URL: ${errorFromKey}/tx/${transactionHash}`
			// );
		}
	}
};

// const getTransaction = async (transactionHash) => {
// 	document.querySelector('#warning').innerText = ``;
// 	document.querySelector('#linkToBridge').childNodes[0].disabled = true;
// 	document.querySelector('#claimButton').innerText = 'Loading...';
// 	let counter = 0;
// 	if(transactionHash.length === 66) {
// 	for(let [key, value] of Object.entries(RPC)) {
// 		let web3 = new Web3(value);
// 		try {
// 			const a = await web3.eth.getTransaction(transactionHash);
// 			let remove = `0x${a.input.substr(10)}`;
// 			const z = await web3.eth.abi.decodeParameters(
// 				[
// 					{
// 						type: 'address',
// 						name: 'token',
// 					},
// 					{
// 						type: 'uint256',
// 						name: 'amount',
// 					},
// 					{
// 						type: 'address',
// 						name: 'receiverAddress',
// 					},
// 					{
// 						type: 'uint256',
// 						name: 'targetChainId',
// 					}
// 				],
// 				remove
// 			);
// 			state.setter = {selectedFromKey: key};
// 			state.setter = {selectedToKey: z[3]};
// 			for (const [key, value] of Object.entries(chains)) {
// 				console.log(key);
// 				if(value.toString() === z[3]) {
// 					document.querySelector('#warning').innerText = '';
// 					if(state.getter.paramsForward === true) {
// 						state.openWindow();
// 						break;
// 					} else {
// 						document.querySelector('#walletAddress').innerText = a.from;
// 						let button = document.querySelector('#claimButton');
// 						button.innerText = 'Claim your CCB link';
// 						button.classList.remove('btn-danger');
// 						button.classList.add('btn-success');
// 						document.querySelector('#linkToBridge').childNodes[0].disabled = false;
// 						break;
// 					}
// 				} else {
// 					document.querySelector('#warning').innerText = `There's an error in your TX hash, make sure it's a valid CCB deposit TX hash.`;
// 					let button = document.querySelector('#claimButton');
// 					button.innerText = 'Failed';
// 					button.classList.remove('btn-success');
// 					button.classList.add('btn-danger');
// 					document.querySelector('#linkToBridge').childNodes[0].disabled = true;
// 				}
// 			}
// 		} catch (error) {
// 			console.log(`Not chain:${key}, pinging the next chain.`);
// 			counter ++;
// 			if(counter === 5) {
// 				document.querySelector('#warning').innerText = `There's an error in your TX hash, make sure it's a valid CCB deposit TX hash.`;
// 				let button = document.querySelector('#claimButton');
// 					button.innerText = 'Failed';
// 					button.classList.remove('btn-success');
// 					button.classList.add('btn-danger');
// 					document.querySelector('#linkToBridge').childNodes[0].disabled = true;
// 			}
// 		}
// 		}
// 	} else {
// 		let button = document.querySelector('#claimButton');
// 		button.innerText = 'Invalid deposit hash';
// 		button.classList.remove('btn-success');
// 		button.classList.add('btn-danger');
// 	}
// };

var txParam;
const checkParams = (txParam) => {
	const queryStringSearch = window.location.search;
	let urlParams = new URLSearchParams(queryStringSearch);
	txParam = urlParams.get('tx');
	if (txParam != null || '') {
		if (txParam.length === 66) {
			state.setter = { txHash: txParam };
			state.setter = { paramsForward: true };
			getTransaction(state.getter.txHash);
			// console.log(state.getter.txHash);
		}
	}
};
checkParams(txParam);

let txInput = document.querySelector('#txInput');
txInput.addEventListener('input', (e) => {
	state.setter = { txHash: e.target.value };
	state.setter = { paramsForward: false };
	getTransaction(state.getter.txHash);
});

let linkToBridge = document.querySelector('#linkToBridge');
linkToBridge.addEventListener('click', () => {
	if (state.getter.selectedFromKey != 0 && state.getter.selectedToKey != 0 && state.getter.txHash != '') {
		if (state.getter.selectedToKey === state.getter.selectedFromKey) {
			let warning = document.querySelector('#warning');
			warning.innerText = `Your chain ID cannot match From: ${state.getter.selectedFromKey} | To: ${state.getter
				.selectedToKey}`;
			document.querySelector('#warning').innerText = 'Please select your to & from chains';
		} else {
			state.openWindow();
		}
	} else {
		document.querySelector('#warning').innerText = 'Please select your to & from chains';
	}
});
