// const RPC = {
//     1: 'https://rpc.ankr.com/eth',
//     56: 'https://rpc.ankr.com/bsc',
//     137: 'https://rpc.ankr.com/polygon',
//     43114: 'https://rpc.ankr.com/avalanche-c',
//     250: 'https://rpc.ankr.com/fantom'
// };

// export default RPC;

const chains = [
	{
		ETH: 1,
		BNB: 56,
		POLYGON: 137,
		AVALANCHE: 43114,
		FANTOM: 250
	},
	{
		1: 'https://rpc.ankr.com/eth',
		56: 'https://rpc.ankr.com/bsc',
		137: 'https://rpc.ankr.com/polygon',
		43114: 'https://api.avax.network/ext/bc/C/rpc',
		250: 'https://rpc.ankr.com/fantom'
	},
	{
		1: 'https://etherscan.io',
		56: 'https://bscscan.com',
		137: 'https://polygonscan.com',
		43114: 'https://snowtrace.io',
		250: 'https://ftmscan.com'
	},
	{
		1: "ETH",
		56: "BNB",
		137: "POLYGON",
		43114: "AVALANCHE",
		250: "FANTOM",
	}
];

export default chains;

// Chain ID of ETH: 1
// Chain ID of BSC: 56
// Chain ID of POLYGON: 137
// Chain ID of AVAX: 43114
// Chain ID of FTM: 250